import URL from 'url-parse';

export const LINK_TYPE_NONE = 'LINK_NONE';
export const LINK_TYPE_INTERNAL = 'LINK_INTERNAL';
export const LINK_TYPE_EXTERNAL = 'LINK_EXTERNAL';
export const LINK_TYPE_EMAIL = 'LINK_EMAIL';

export const completeUrl = (hyperlink) => {
  if (!hyperlink || hyperlink === '' || hyperlink.indexOf('/') === 0) { return hyperlink; }
  if (hyperlink.indexOf('http') === 0) {
    return URL(hyperlink).toString();
  }
  const url = URL(`http://${hyperlink}`);
  return url.toString();
};

export const getLinkType = (hyperlink, defaultLinkType = LINK_TYPE_NONE) => {
  if (hyperlink === '' || !hyperlink) {
    return defaultLinkType;
  } if (hyperlink === false) {
    return LINK_TYPE_INTERNAL;
  } if (hyperlink.indexOf('/') === 0) {
    return LINK_TYPE_INTERNAL;
  } if (hyperlink.indexOf('@') !== -1) {
    return LINK_TYPE_EMAIL;
  }
  return LINK_TYPE_EXTERNAL;
};
